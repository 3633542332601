<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <DoodleGeneralForm title="Change Display Name"
                       in-progress-message="JDoodle is trying to change your display name, Please wait..."
                        success-message="Display Name Successfully Updated."
                        action-text="Change Display Name"
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       @action="changeDisplayName"
                       @close="$refs.sideBar.emitToggle()">
        <DoodleInput field-name="displayName" label-text="New Display Name" type="text"
                     left-icon="address-card"
                     rule="required"
                     v-model="doodleForm.displayName" place-holder="New Display Name"/>
    </DoodleGeneralForm>
  </SideBar>
</template>

<script>
import formMixin from '../assets/javascript/form-mixin'
import DoodleGeneralForm from './Utils/DoodleGeneralForm'
import DoodleInput from './Utils/DoodleInput'
import SideBar from './Utils/SideBar'

export default {
  name: 'changeDisplayName',
  components: { SideBar, DoodleInput, DoodleGeneralForm },
  inject: ['$validator'],
  mixins: [formMixin],
  data: function () {
    return {
      doodleForm: {
        displayName: null
      }
    }
  },
  methods: {
    changeDisplayName () {
      this.executeAPI({
        url: '/api/register/changeDisplayName',
        data: { 'displayName': this.doodleForm.displayName },
        method: 'post',
        successAction: () => { this.$store.commit('login', this.doodleForm.displayName) },
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GLOBAL,
        jdaEvent: 'change-display-name',
        jdaLabel: ''
      })
    }
  }
}
</script>
